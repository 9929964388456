<template>
  <div class="b2c-page-bg">  
    <div>
      <div class="fs-sp">
        <div class="w-100 b2c-bg">
          <div class="w-100 b2c-bg-top">
            <div class="b2c-top-left"></div>
            <div class="b2c-top-center">Vé xe rẻ</div>
            <div class="b2c-top-right"></div>
          </div>
        </div>
        <div class="w-100">
        </div>
      </div>
      <div class="fs-pc b2c-vexere">
        <div class="b2c-vexere-header">
          <h2>Vé xe rẻ</h2>
        </div>
        <img src="@/assets/images/services/vexere.png" class="w-100"/>
        <div class="toolbox">
          <h2 class="text-center">Đặt vé xe iTel trực tuyến</h2>
          <div class="row form">
            <div class="col-lg-6 col-md-12">
              <div class="row row1 pl-2 pr-2">
                <div class="col1">
                  <b-form-group label="NƠI XUẤT PHÁT">
                    <v-select class="form-control" v-model="from" :clearable="true" label="name" :options="from_location" placeholder="Chọn địa điểm"></v-select>
                  </b-form-group>
                </div>
                <div class="col2 mt-1" role="button" @click="switchLocation">

                </div>
                <div class="col3">
                  <b-form-group label="NƠI ĐẾN">
                    <v-select class="form-control" v-model="to" :clearable="true" label="name" :options="to_location" placeholder="Chọn địa điểm"></v-select>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="row">
                
                <div class="col-4">
                  <b-form-group label="NGÀY ĐI">
                    <date-picker class="form-control w-100" format="DD/MM/YYYY" v-model="date" valueType="format"></date-picker>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <b-form-group label="MÃ KHUYẾN MẠI">
                    <b-form-input class="form-control" disabled/>
                  </b-form-group>
                </div>
                <div class="col-4" style="vertical-align:bottom">
                  <b-button class="btn w-100" variant="primary" @click="showModal">TÌM VÉ XE RẺ</b-button>
                  
                </div>
              </div>
            </div>
          </div>
          <p class="pl-1 text"><i>Dành cho tất cả các tuyến, Giảm 3% Mã ưu đãi <b>ITEL087</b> .Chi tiết ưu đãi xem <b class="text-primary" role="button" @click="$bvModal.show('modal-agree')">tại đây</b></i></p>
        </div>
        <div style="min-height:500px">
          
        </div>
      </div>
      <div class="fs-sp b2c-vexere">
        <div class="b2c-vexere-header">
          <h2>Vé xe rẻ</h2>
        </div>
        <img src="@/assets/images/services/vexere.png" class="w-100"/>
        <div class="toolbox pt-2 pl-3 pr-3">
          <div class="row">
            <div class="col-12 pt-3 mt-3">
              <b-form-group label="NƠI XUẤT PHÁT">
                  <v-select class="form-control" v-model="from" :clearable="true" label="name" :options="from_location" placeholder="Chọn địa điểm"></v-select>
                </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group label="NƠI ĐẾN">
                    <v-select class="form-control" v-model="to" :clearable="true" label="name" :options="to_location" placeholder="Chọn địa điểm"></v-select>
                  </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group label="NGÀY ĐI">
                    <date-picker class="form-control w-100" format="DD/MM/YYYY" v-model="date" valueType="format"></date-picker>
                  </b-form-group>
            </div>
            <div class="col-12">
              <b-form-group label="MÃ KHUYẾN MẠI">
                    <b-form-input class="form-control" disabled/>
                  </b-form-group>
            </div>
            <div class="col-12">
              <b-button class="btn w-100" variant="primary" @click="showModal">TÌM VÉ XE RẺ</b-button>
            </div>
          </div>
          <p class="pl-1 text pt-2"><i>Dành cho tất cả các tuyến, Giảm 3% Mã ưu đãi “<b>ITEL087</b>” .Chi tiết ưu đãi xem <b class="text-primary" role="button" @click="$bvModal.show('modal-agree')">tại đây</b></i></p>
        </div>
        <div style="min-height:500px">
          
        </div>
      </div>
    </div>
    <b-modal body-class="p-3" hide-footer id="modal-info" title-class="pt-2 pl-2" title="Thông báo" centered>
      <b-row>
        <b-col cols="12">
          <p class="text" style="font-size:16pt">
            Xin lỗi, tuyến đường chưa có xe!
          </p>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal body-class="p-2" modal-class="mt-4" hide-header hide-footer id="modal-info" title="Thông báo!">
      <div class="row">
        <div class="col-12">
          <h5 class="modal-title">
            <img src="@/assets/images/logo/logo.svg" style="width:30%" class="mb-4 border-right border-2 pr-2" alt="icon-notify">
            <img src="@/assets/images/shop/partner/vexere_web.png" style="width:40%" class="ml-2 mb-4" alt="icon-notify">
          </h5>
          <div>
            <p class="text vietlott-info">Bạn sẽ được chuyển tới màn hình đặt vé xe khách của Công ty TNHH Thương mại dịch vụ Vexere. Giá vé sẽ áp dụng theo chính sách hiện hành của Công ty VEXERE. Trân trọng!</p>
        </div>
        </div>
        <div class="col-12 text-center pt-2 mt-1 mb-3">
          <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-info')">Đóng</button>
          <a class="fs-but1" @click="handleClick()">Tiếp tục</a>
        </div>
      </div>
    </b-modal>
    <b-modal size="lg" body-class="p-2" hide-header hide-footer id="modal-agree" title="">
      <div class="row">
        <div class="col-12">
          <h5 class="modal-title pt-1">Thông tin chương trình</h5>
          <p class="text text-center vietlott-info">CHƯƠNG TRÌNH ƯU ĐÃI SỬ DỤNG DỊCH VỤ VEXERE VỚI ITEL</p>
          <p class="text vietlott-info">
            Khách hàng cần lưu ý rằng khi Khách hàng tham gia Chương trình ƯU ĐÃI SỬ DỤNG DỊCH VỤ VEXERE VỚI ITEL (“Chương trình”) hoặc đồng ý nhận các ưu đãi theo Chương Trình, Khách hàng sẽ được xem là đã đọc, hiểu và chấp thuận bản THÔNG TIN CHƯƠNG TRÌNH này.<br>
            <u style="text-decoration:none">
              <li>1. Tên chương trình: “Ưu đãi sử dụng dịch vụ Vexere với iTel”</li>
              <li>2. Địa bàn thực hiện chương trình: Cả nước.</li>
              <li>3. Thời gian : Chương trình có hiệu lực từ ngày <b>01/04/2023</b> đến hết ngày <b>30/06/2023</b>.</li>
              <li>4. Đối tượng được áp dụng: Khách hàng sử dụng dịch vụ Vexere trên trang web/app iTel.</li>
              <li>5. Nội dung chi tiết chương trình:<br>
                <div class="pl-3">
                  Khách hàng sẽ được hưởng mức chiết khấu sau đây trên mỗi giao dịch thành công, không bị hủy hay hoàn:<br>
                  Tất cả các tuyến: Giảm 3% <br>
                  Mã ưu đãi: <b>ITEL087</b>.
                </div>
              </li>
              <li>6. Cách thức tham gia:<br>
                <div class="pl-3">
                  Khách hàng thực hiện mua vé xe thông qua web/app iTel . Trong bước thanh toán trên trang của Vexere, Khách hàng nhập các mã ưu đãi nêu trên để được hưởng Ưu Đãi.<br>
                  Click vào đường link: <a href="https://myitel.onelink.me/1Wbg/m6mjer0j">https://myitel.onelink.me/1Wbg/m6mjer0j</a> (với những Khách hàng chưa có My iTel)<br>
                  Hoặc quét mã QR-code và thực hiện đăng ký thành công My iTel:<br>
                  <img src="@/assets/images/services/vexere_qr.png" alt="QR-code" style="width:150px">
                </div>
              </li>
              <li>7. Điều khoản chung:<br>
                <div class="pl-3">
                  - Ưu Đãi không được thay thế bằng sản phẩm khác, không được quy đổi ra tiền mặt hoặc chuyển nhượng dưới bất kỳ hình thức nào.<br>
                  - Việc mua vé xe tại Vexere sẽ tuân theo điều khoản và điều kiện của Vexere. Đối với những thắc mắc liên quan đến việc mua vé xe của Vexere, Khách hàng liên hệ Vexere để được hướng dẫn và giải quyết.<br>
                  - Hotline Vexere: 1900 969 681<br>
                  - Trường hợp có bất kỳ khoản phí, thuế nào phát sinh do việc nhận ưu đãi từ Chương trình, Khách hàng theo đây đồng ý chịu trách nhiệm tự kê khai và đóng các khoản phí, thuế nêu trên với cơ quan nhà nước có thẩm quyền (nếu có)
                </div>
              </li>
            </u>
          </p>
        </div>
        <div class="col-12 text-center pt-0 mt-0 mb-2">
          <button class="fs-but1-white mr-1" @click="$bvModal.hide('modal-agree')">Đóng</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import verere from '@/store/vexere'
import vSelect from "vue-select";
import location from '@/store/vexere_location'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

import {
  BFormInput,BFormGroup,BButton
} from "bootstrap-vue";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import vexere from '@/store/vexere';
SwiperCore.use([Navigation, Pagination]);
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images["assets/images/ve-itel/" + item.replace("./", "")] = r(item);
  });
  return images;
}
const images = importAll(
  require.context("@/assets/images/ve-itel/", false, /.(png)$/)
);

export default {
  components: {
    Swiper,
    SwiperSlide,
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
    DatePicker
  },
  data() {
    return {
      iframeSrc: '',
      from: null,
      to: null,
      date:null
    };
  },
  watch: {
    $route(to, from) {
      console.log('a');
    },
  },
  created() {
    //console.log(verere);
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
    };
  },
  mounted() {
    this.$bvModal.show('modal-vietlott');
  },
  computed:{
    from_location() {
      var x = verere.map(function( el ){  return el.from_search_id+""; })
      return location.filter(i=>x.includes(i.id));
    },
    to_location() {
      var x = verere.map(function( el ){  return el.to_search_id+""; })
      return location.filter(i=>x.includes(i.id));
    }
  },
  methods: {
    showModal(){
      if (this.from==null || this.to==null || this.date=='' || this.date==null) {
        this.$toast.warning(
          "Vui lòng nhập đủ thông tin để tìm kiếm vé!",
          { icon: true, closeButton: "button" }
        );
        return;
      }
      this.$bvModal.show('modal-info');
    },
    handleClick(){
      this.loading(true);
      var x = vexere.find(i=>i.from_search_id==this.from.id && i.to_search_id==this.to.id);
      if (x){
        window.location.assign(x.url+"?date="+this.date+"&aid=261&utm_source=ITEL");
      }else{
        this.loading(false);
        this.$toast.warning(
          "Chưa có tuyến đường này!",
          { icon: true, closeButton: "button" }
        );
      }
    },
    switchLocation(){
      var temp = this.from;
      this.from = this.to;
      this.to = temp;
    },
    Img(pic) {
      return images[pic];
    },
  },
};
</script>
<style>
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.b2c-bg {
  background: rgba(237, 31, 36, 1);
  padding-bottom: 32px;
}
.b2c-bg-top {
  background: rgba(237, 31, 36, 1);
  display: flex;
  margin-bottom: 0px;
}
.b2c-top-left::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjgiIGhlaWdodD0iMjkiIHZpZXdCb3g9IjAgMCAyOCAyOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE3IDIwLjE0MjhMMTEgMTQuMTQyOEwxNyA4LjE0MjgyIiBzdHJva2U9IndoaXRlIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
}
.b2c-top-left {
  height: 44px;
  padding: 8px 14px;
  width: 20%;
}
.b2c-vexere-header::before{
  content: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDEzTDEgN0w3IDEiIHN0cm9rZT0iIzQyNDI0MiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==');
  position: absolute;
  top: 18px;
}
.b2c-vexere-header {
  max-width: 122.4rem;
  margin-left: auto;
  margin-right: auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
}
.b2c-vexere-header h2 {
  padding-left: 20px;
  font-size: 24px;
  font-weight: 700;
  line-height: 50px;
  color: #424242;
  width: 50%;
}
.b2c-pc-header {
  display: flex;
}
.b2c-pc-header h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 69px;
  color: #424242;
  width: 50%;
}
.b2c-pc-header .nav{
  background-image:none !important
}
.b2c-top-center {
  width: 80%;
  height: 22px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding-top: 7px;
}
.b2c-top-right {
  width: 20%;
}
.modal-title{
  padding-top: 35px;
  font-size: 30px !important;
  color: '#373737' !important;
  font-weight: 700;
  text-align: center;
}
.vietlott-info{
  line-height: 1.5;
  padding: 10px 30px
}
.fs-but1 {
	padding:0.8rem 2.55rem;
	background-color:#ff2424;
	/*text-transform:uppercase;*/
	font-size:1.8rem;
	font-weight:500;
	color:#fff !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 0);
	cursor:pointer;
	text-align:center;
}
.fs-but1-white {
	padding:0.8rem 4.55rem;
	background-color:#fff;
	font-size:1.8rem;
	font-weight:500;
	color:#ff2424 !important;
	-webkit-border-radius:2rem;
	border-radius:2rem;
	border:2px solid rgba(255, 36, 36, 1);
	cursor:pointer;
	text-align:center;
}
.b2c-vexere{
  position: relative;
}
.b2c-vexere .toolbox{
  min-height: 199px;
  background: #F5F5F5;
  box-shadow: 0px 4px 31px rgba(0, 0, 0, 0.25);
  border-radius: 21px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -75px;
  width:80%;
  z-index: 100;
  position:relative;
  padding: 22px 4rem;
}
.b2c-vexere .toolbox::before{
  content: "";
  position: absolute;
  width: 134px;
  display:inline-block;
  height:43px;
  content: "";
  background:url('~@/assets/images/services/hor-vexere.png') no-repeat;
  background-size: 134px 43px;
}
.b2c-vexere .toolbox h2{
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  color: #000000;
}
.b2c-vexere .col-form-label{
  font-size: 14px;
  line-height: 15px;
  color: #7D7D7D;
  font-weight: 600;
}
.b2c-vexere .form{
  margin-top: 26px;
}
.b2c-vexere button{
  font-weight: 700;
  font-size: 17.7285px;
  line-height: 17.7px;
  margin-top: 20px;
  text-align: center;
  color: #FFFFFF;
}
.b2c-vexere .row1 .col1{
  width: 47%;
}
.b2c-vexere .row1 .col2{
  width: 6%;
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyNiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTUuNDkxODkgMTYuNjQ1NkM1LjQ5MTg5IDE3Ljc4MDggNS40OTE4OSAxOC44NSA1LjQ5MTg5IDIwQzMuNjM5MDQgMTguMzEyMSAxLjgzNTczIDE2LjY3MDEgMCAxNC45OTc4QzEuODI3NzggMTMuMzMyOCAzLjYyOTg2IDExLjY5MDkgNS40ODU3OCAxMEM1LjQ4NTc4IDExLjE0NzEgNS40ODU3OCAxMi4yMTI1IDUuNDg1NzggMTMuMzE3M0MxMS4wMDcgMTMuMzE3MyAxNi40OTQ2IDEzLjMxNzMgMjIgMTMuMzE3M0MyMiAxNC40MzkxIDIyIDE1LjUyMTYgMjIgMTYuNjQ0OUMxNi41MTQ4IDE2LjY0NTYgMTEuMDI3MiAxNi42NDU2IDUuNDkxODkgMTYuNjQ1NloiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik00IDYuNjkyMTRDNCA1LjU1MDk0IDQgNC40NzY1MiA0IDMuMzYwNTRDOS40OTE3NSAzLjM2MDU0IDE0Ljk3NjggMy4zNjA1NCAyMC40OTc5IDMuMzYwNTRDMjAuNDk3OSAyLjI0MDg1IDIwLjQ5NzkgMS4xNjI3MiAyMC40OTc5IDBDMjIuMzU4NCAxLjY5MzI2IDI0LjE2MjEgMy4zMzUzMSAyNiA1LjAwNzc5QzI0LjE3MTkgNi42NzIxMSAyMi4zNjk0IDguMzEzNDIgMjAuNTE3NCAxMEMyMC41MTc0IDguODYzOTkgMjAuNTE3NCA3Ljc5ODQ3IDIwLjUxNzQgNi42OTIxNEMxNC45OTk0IDYuNjkyMTQgOS41MTQzNyA2LjY5MjE0IDQgNi42OTIxNFoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=') no-repeat;
  background-position: center;
}
.b2c-vexere .row1 .col3{
  width: 47%;
}
.b2c-vexere fieldset{
  padding: 0;
}
.b2c-vexere .vs__clear{
  margin-top: 0;
}
.b2c-vexere .vs__dropdown-toggle{
  border: none;
}
.b2c-vexere .mx-input{
  border: none;
  padding: 10px;
  font-size: 16px;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-top: 2px;
}
.b2c-vexere .form-control{
  padding: 0.4rem 0.6rem;
}
</style>